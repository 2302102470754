import { Stack } from '@mui/material';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import { SummaryHeadingColumn } from './Summary/SummaryHeadingColumn';
import { useQuery } from '@apollo/client';
import { RenewalInfo } from './products';
import { GET_RENEWALS_AT } from './renewals.graphql';
import { Summary } from './Summary/Summary';
import { createSummary } from './Summary/summaryUtils';

export const SummaryFor: FC<{ date: Moment }> = ({ date }) => {
  const { renewals } = useRenewalsAt(date);
  const summary = renewals ? createSummary(renewals) : undefined;

  return (
    <Stack direction="row" justifyContent="space-between">
      <SummaryHeadingColumn />
      <Summary summary={summary} />
    </Stack>
  );
};

function useRenewalsAt(at: moment.Moment) {
  const month = at.month() + 1;
  const year = at.year();

  const now = moment();

  const { loading, data } = useQuery<{ renewals: RenewalInfo[] }>(GET_RENEWALS_AT, {
    variables: { month, year, at: now.format('YYYY-MM-DD') },
  });

  return {
    renewals: data?.renewals,
    loading,
  };
}
